import {stringDateToDate} from "./actions";

export const Patients = {
  "riad": {
    "gender": "Herr",
    "firstname": "Riad",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("30.05.1988"),
    "street": "Jakobistraße",
    "houseNo": "6",
    "zip": 51381,
    "city": "Leverkusen"
  },
  "nada": {
    "gender": "Frau",
    "firstname": "Nada",
    "lastname": "Jaha",
    "dateOfBirth": stringDateToDate("26.06.1999"),
    "street": "Jakobistraße",
    "houseNo": "6",
    "zip": 51381,
    "city": "Leverkusen"
  },
  "lina": {
    "gender": "Frau",
    "firstname": "Lina",
    "lastname": "Jaha",
    "dateOfBirth": stringDateToDate("22.09.2005"),
    "street": "Wolfhagen",
    "houseNo": "58a",
    "zip": 40764,
    "city": "Langenfeld"
  },
  "youness": {
    "gender": "Herr",
    "firstname": "Youness",
    "lastname": "Jaha",
    "dateOfBirth": stringDateToDate("18.01.20012"),
    "street": "Wolfhagen",
    "houseNo": "58a",
    "zip": 40764,
    "city": "Langenfeld"
  },
  "yasmina": {
    "gender": "Frau",
    "firstname": "Yasmina",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("09.12.1989"),
    "street": "Hansastraße",
    "houseNo": "42",
    "zip": 46446,
    "city": "Emmerich am Rhein"
  },
  "rafik": {
    "gender": "Herr",
    "firstname": "Rafik",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("09.07.1985"),
    "street": "Schleiermacherweg",
    "houseNo": "3",
    "zip": 63454,
    "city": "Hanau"
  },
  "fouad": {
    "gender": "Herr",
    "firstname": "Fouad",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("02.04.1983"),
    "street": "Daimlerstraße",
    "houseNo": "5",
    "zip": 63477,
    "city": "Maintal"
  },
  "mama": {
    "gender": "Frau",
    "firstname": "Malika",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("03.06.1959"),
    "street": "Schleiermacherweg",
    "houseNo": "3",
    "zip": 63454,
    "city": "Hanau"
  },
  "baba": {
    "gender": "Herr",
    "firstname": "Miloud",
    "lastname": "Hassani",
    "dateOfBirth": stringDateToDate("19.01.1951"),
    "street": "Schleiermacherweg",
    "houseNo": "3",
    "zip": 63454,
    "city": "Hanau"
  },
  "youssef": {
    "gender": "Herr",
    "firstname": "Youssef",
    "lastname": "Allouch",
    "dateOfBirth": stringDateToDate("22.01.1998"),
    "street": "Auf der Kuhweide",
    "houseNo": "13",
    "zip": 44269,
    "city": "Dortmund"
  },
  "iman": {
    "gender": "Frau",
    "firstname": "Iman",
    "lastname": "Chioa Allouch",
    "dateOfBirth": stringDateToDate("29.12.1997"),
    "street": "Auf der Kuhweide",
    "houseNo": "13",
    "zip": 44269,
    "city": "Dortmund"
  },
}