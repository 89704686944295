import React, {useEffect} from 'react';
import "../App.css";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import Card from '@mui/material/Card';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {GenerateTest} from "./GenerateTest";

function GenerateTestFromForm() {
    const queryParams = new URLSearchParams(window.location.search);

    let defaultValues = {
        gender: "Herr",
        firstname: "",
        lastname: "",
        dateOfBirth: new Date(),
        street: "",
        houseNo: "",
        zip: "",
        city: "",
    };

    const [value, setValue] = React.useState(new Date());
    const [formValues, setFormValues] = React.useState(defaultValues);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    useEffect(() => {
        handleInputChange({target: {name: "dateOfBirth", value: value}});
    }, [value]);

    const handleSubmit = () => {
        GenerateTest(formValues);
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form onSubmit={handleSubmit}>
                    <Card style={{padding: "25px"}} variant="outlined">
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <RadioGroup
                                    row
                                    aria-labelledby="gender"
                                    name="gender"
                                    value = {formValues.gender}
                                    onChange = {handleInputChange}
                                >
                                    <FormControlLabel value="Herr" control={<Radio />} label="Herr" />
                                    <FormControlLabel value="Frau" control={<Radio />} label="Frau" />
                                </RadioGroup>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    name = "firstname"
                                    type="text"
                                    label="Vorname"
                                    variant="outlined"
                                    value = {formValues.firstname}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    name = "lastname"
                                    type="text"
                                    label="Nachname"
                                    variant="outlined"
                                    value = {formValues.lastname}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <DatePicker
                                    inputFormat="dd.MM.yyyy"
                                    mask="dd.MM.yyyy"
                                    maxDate={new Date()}
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="Geburtsdatum"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} style={{width: "100%"}} helperText={null} />}
                                />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    type="text"
                                    label="Straße"
                                    variant="outlined"
                                    name = "street"
                                    value = {formValues.street}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    type="text"
                                    label="Hausnummer"
                                    variant="outlined"
                                    name = "houseNo"
                                    value = {formValues.houseNo}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    type="number"
                                    label="PLZ"
                                    variant="outlined"
                                    name = "zip"
                                    value = {formValues.zip}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    style = {{width: "100%"}}
                                    type="text"
                                    label="Stadt"
                                    variant="outlined"
                                    name = "city"
                                    value = {formValues.city}
                                    onChange = {handleInputChange}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Button fullWidth variant="contained" type="submit">PDF erstellen</Button>
                            </Grid>
                        </Grid>
                    </Card>
                </form>
            </LocalizationProvider>
        </div>
    );
}

export default GenerateTestFromForm;