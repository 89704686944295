import { jsPDF } from "jspdf";
import cimg from "../bescheinidung.png";
import {newTestDate, prettyDateFormat, setCustomText} from "../actions";

export function GenerateTest(formValues) {
    let doc = new jsPDF();
    let text = "";
    doc.addImage(cimg, "png", 0, 0, 210, 297);

    // Fixtext
    text = "LD Healthcare\nCarlo Schmid Str.20 a • 40595 Düsseldorf\n47078";
    doc = setCustomText(doc, text, 90, 66, 11, '#04087D');

    // Fullname
    text = formValues.gender + " " + formValues.firstname + " " + formValues.lastname;
    doc = setCustomText(doc, text, 70, 88, 11, '#04087D');

    // Address
    text = formValues.street + " " + formValues.houseNo + ", " + formValues.zip + " " + formValues.city
    doc = setCustomText(doc, text, 70, 96, 11, '#04087D');

    // Date of birth
    text = prettyDateFormat(formValues.dateOfBirth);
    doc = setCustomText(doc, text.date, 70, 104, 11, '#04087D');

    // Fixtext
    text = "COVID - 19 Antigen Schnelltest - CHIL";
    doc = setCustomText(doc, text, 70, 127.5, 8, '#04087D');

    // Fixtext
    text = "CHIL TIBBI MAL.SAN.TIC.LTD.STI";
    doc = setCustomText(doc, text, 70, 134.5, 8, '#04087D');

    //let newTestDate = getYesterdaysDate();
    let testDate = newTestDate();

    // Date/Time of the Test
    text = testDate.date + ", " + testDate.hour + ":" + testDate.minute + " Uhr / o'clock";
    doc = setCustomText(doc, text, 95, 143, 11, '#04087D');

    // Fixtext
    text = "LD Healthcare";
    doc = setCustomText(doc, text, 70, 150.5, 11, '#04087D');

    // Fixtext
    text = testDate.weekday + " " + testDate.date;
    doc = setCustomText(doc, text, 161.5, 220, 10, '#020708');

    // Fixtext
    text = testDate.weekday + " " + testDate.date + " | 87910dc9-219b-4b68-8025-fcd9dfe12648";
    doc = setCustomText(doc, text, 76, 292, 7, '#020708');

    //doc.save("a5.pdf");
    //window.open(doc.output('bloburl'), "_blank");
    window.open(doc.output('bloburl', { filename: "a5.pdf" }));
}