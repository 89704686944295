import React, {useEffect} from 'react';
import { jsPDF } from "jspdf";
import cimg from "../bescheinidung.png";
import {getYesterdaysDate, newTestDate, prettyDateFormat, setCustomText, stringDateToDate} from "../actions";
import "../App.css";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import Card from '@mui/material/Card';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Patients } from "../patienten.js";
import {useParams} from "react-router-dom";
import {GenerateTest} from "./GenerateTest";

function GenerateTestForPerson() {
    const {name} = useParams();
    let formValues = Patients[name];

    const handleSubmit = () => {
        GenerateTest(formValues);
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Card style={{padding: "25px"}} variant="outlined">
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Button fullWidth variant="contained" type="submit">{`PDF erstellen für ${formValues.firstname}`}</Button>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </div>
    );
}

export default GenerateTestForPerson;