import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import keycloak from "./Keycloak";
import Nav from "./components/Nav";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import PrivateRoute from "./helpers/PrivateRoute";
import Home from "./pages/Homepage";
import Secured from "./pages/Securedpage";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import GenerateTestFromForm from "./components/GenerateTestFromForm";
import GenerateTestForPerson from "./components/GenerateTestForPerson";

let isLocalhost = window.location.host.includes("localhost:3000");
isLocalhost = false;

ReactDOM.render(
  <React.StrictMode>
      {
          isLocalhost ?
              <ReactKeycloakProvider authClient={keycloak}>
                  <Nav />
                  <Router>
                      <Routes>
                          <Route exact={true} path="/app" element={
                              <PrivateRoute>
                                  <GenerateTestForPerson />
                              </PrivateRoute>
                          } />
                          <Route exact={true} path="/" element={<Home />} />
                          <Route exact={true} path="/secured" element={
                              <PrivateRoute>
                                  <Secured />
                              </PrivateRoute>
                          } />
                      </Routes>
                  </Router>
              </ReactKeycloakProvider>
              :
              <Router>
                  <Routes>
                      <Route exact={true} path="/" element={<App />} />
                      <Route exact={true} path="/app" element={<GenerateTestFromForm />} />
                      <Route exact={true} path="/:name" element={<GenerateTestForPerson />} />
                  </Routes>
              </Router>
      }
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
